import { ActionInterface } from '@/types/breadcrumb';

export default class Step {
  label!: string;
  view!: string;
  selectable?: boolean = true;
  actions?: Array<ActionInterface>;

  constructor(label: string, view: string, selectable: boolean = true, actions?: Array<ActionInterface>) {
    this.label = label;
    this.view = view;
    this.selectable = selectable;
    this.actions = actions;
  }
}
