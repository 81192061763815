





























































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import moment from 'moment';
import _ from 'lodash';

import Timeout from '@/utils/Timeout';
import { FirmItemUpdateForm } from '@/types/firm/firm';
import { VForm } from '@/types/v-form';
import { JobClassificationServiceInterface } from '@/services/interfaces';
import { lazyInject, SERVICE_IDENTIFIER } from '@/ioc/container';

import Job from '@modules/declaration/entities/Job';
import JobClassification from '@models/firm/JobClassification';
import Exposition from '@models/firm/Exposition';

const firmModule = namespace('firm');
const firmUpdateModule = namespace('firmUpdate');

@Component
export default class UpdateJob extends Vue implements FirmItemUpdateForm {
  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Object })
  value!: Job;

  @Prop({ type: Boolean, default: false })
  isNew!: boolean;

  @State(state => state.UserModule.currentFirmId)
  currentFirmId!: number;

  @firmModule.Getter('getJobExpositions')
  expositions!: Array<Exposition>;

  @firmModule.Getter('getFirmExpositions')
  getFirmExpositions!: (id: number) => Array<Exposition>;

  @firmUpdateModule.Action('addJob')
  addJob!: (item: Job) => Promise<Job>;

  @firmUpdateModule.Action('updateJob')
  updateJob!: (item: Job) => Promise<Job>;

  @lazyInject(SERVICE_IDENTIFIER.JobClassificationServiceInterface)
  jobClassificationService!: JobClassificationServiceInterface;

  buffer: Job = new Job();
  classifications: Array<JobClassification> = [];
  loading: boolean = false;
  disableSaveButton: boolean = false;

  $refs!: {
    form: VForm
  };

  mounted() {
    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      this.buffer.edit = true;
    }
  }

  get rules(): any {
    return {
      label: [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
      ],
      classification: [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
        {
          message: this.$t('form.job_classification_archived'),
          validator(rule: any, value: any, callback: (p?: any) => void) {
            const jobC = this.classifications.find((item: any) => item.id === value);
            if (!jobC || jobC.deletedAt !== null) {
              callback(rule.message);
            }

            callback();
          },
          trigger: 'blur',
          object: this.value,
          classifications: this.classifications,
        },
      ],
    };
  }

  get isModify() {
    return !_.isEqualWith(this.value, this.buffer, (v1, v2, k) => (k === 'new' || k === 'edit' ? true : undefined));
  }

  get dateJob() {
    const version = !this.isModify || this.buffer.edit ? this.value.version : this.value.version + 1;
    return `Version ${version}`;
  }

  get firmExpositionsName(): Array<string> {
    return this.getFirmExpositions(this.currentFirmId).filter(exposition => !exposition.forEmployee).map(exposition => exposition.name);
  }

  @Watch('value', { immediate: true })
  async onValueChange() {
    this.buffer = Object.assign(new Job(), this.value);
    if (this.buffer.classification) {
      const currentClassification = await this.jobClassificationService.get(this.buffer.classification);
      this.classifications.push(currentClassification);
    }
  }

  @Watch('isModify')
  onIsModify(value: boolean) {
    if (this.buffer.isSave) {
      this.buffer.new = value;
    }
  }

  async search(searchText: string) {
    if (!searchText) {
      this.classifications = [];
      return;
    }

    this.loading = true;
    this.classifications = await this.jobClassificationService.search(searchText);
    this.loading = false;
  }

  sanitize() {
    this.buffer.label = _.accentsTidy(this.buffer.label.trim()).toUpperCase();
    this.buffer.description = this.buffer.description ? this.buffer.description.trim() : null;
  }

  validate(e?: Event): Promise<any> {
    if (e) e.preventDefault();

    this.disableSaveButton = true;
    this.sanitize();
    if (!this.isModify && !this.isNew && !this.buffer.edit) {
      this.$emit('valid');
      return Promise.resolve();
    }

    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      this.disableSaveButton = false;
      return Promise.reject(errorFields);
    }

    return this.save();
  }

  @Timeout()
  save(): Promise<any> {
    const promise = this.buffer.new ? this.addJob(this.buffer) : this.updateJob(this.buffer);
    return promise.finally(() => {
      this.$emit('valid');
      this.disableSaveButton = false;
    });
  }
}
