export enum PersonSexEnum {
  MEN = 1,
  WOMEN,
  UNDEFINED,
}

export const PersonSexEnumName = new Map<number, string>([
  [PersonSexEnum.MEN, 'Homme'],
  [PersonSexEnum.WOMEN, 'Femme'],
  [PersonSexEnum.UNDEFINED, 'Indeterminé'],
]);
