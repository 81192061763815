






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ActionInterface } from '@/types/breadcrumb';
import Breadcrumb from '@/components/Breadcrumb.vue';

@Component({
  components: {
    Breadcrumb,
  },
})
export default class LayoutSideBar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly actions!: Array<ActionInterface>;
  @Prop({ type: Object, default: () => {} }) readonly printAction!: Object;
  @Prop({ type: Object, default: () => {} }) readonly printActionBtn!: Object;
  @Prop({ type: Boolean, default: true }) readonly hideSideBar!: boolean;
}
