











































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import ListBus from '@/bus/ListBus';

import { FirmItemType } from '@/types/firm/firm';
import { namespace } from 'vuex-class';
import Job from '@models/firm/Job';
import { lazyInject, SERVICE_IDENTIFIER } from '@/ioc/container';
import { JobClassificationServiceInterface } from '@/services/interfaces';
import {
  FirmListAction,
  FirmListHandler,
  FirmListItem,
  FirmListType,
} from '../types/firm-list';

const firmUpdateModule = namespace('firmUpdate');

@Component({
  name: 'FirmList',
})
export default class FirmList extends Vue {
  @Prop({ type: Object, required: true })
  item!: FirmListItem;

  @Prop({ type: Object, required: true })
  root!: FirmListItem;

  @Prop({ type: String, default: null })
  parent!: string;

  @Prop({ type: Array })
  list!: Array<FirmListItem>;

  @Prop({ type: Number, default: 0 })
  index!: number;

  @Prop({ type: Number, default: 0 })
  level!: number;

  @Prop({ type: Array, default: () => [] })
  actions!: Array<FirmListAction>;

  @Prop({ type: Array, default: () => [] })
  types!: Array<FirmListType>;

  @Prop({ type: Array, default: () => [] })
  allowSelected!: Array<string>;

  @Prop({ type: Array, default: () => [] })
  disableDraggable!: Array<string>;

  @Prop({ type: Object, default: null })
  selectedItem!: FirmListItem | null;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job | null;

  @lazyInject(SERVICE_IDENTIFIER.JobClassificationServiceInterface)
  jobClassificationService!: JobClassificationServiceInterface;

  onDrag: boolean = false;
  close: boolean = false;
  hasError: boolean = false;

  get type() {
    return this.types.find((type: FirmListType) => type.type === this.item.type);
  }

  get isDraggable() {
    return this.disableDraggable.indexOf(this.item.type) === -1 && this.level > 0;
  }

  get isSelectable(): boolean {
    return this.allowSelected.indexOf(this.item.type) !== -1 && this.level > 0;
  }

  get isSelected(): boolean {
    return this.selectedItem !== null && this.selectedItem.uuid === this.item.uuid;
  }

  get showChildren() {
    const showChildrenOnSelect = this.type !== undefined ? this.type.showOnSelect : true;
    return !showChildrenOnSelect || (showChildrenOnSelect && this.isSelected);
  }

  get showBody() {
    return this.allowedTypes.length && (this.showChildren || this.children.length > 0 || this.onDrag);
  }

  get allowedActions(): Array<FirmListAction> {
    return this.getAllowedList(this.actions);
  }

  get allowedTypes(): Array<FirmListType> {
    return this.getAllowedList(this.types);
  }

  get allowedFirmTypes(): FirmItemType[] {
    return this.allowedTypes.map(({ type }) => type);
  }

  get children(): FirmListItem[] {
    if (this.showChildren) {
      return this.item.children ? this.item.children : [];
    }

    return this.item.children ? this.item.children.filter(child => (this.selectedItem && this.selectedItem.uuid === child.uuid) || false) : [];
  }

  get placeholderLabel(): string {
    return this.allowedTypes.map(({ label }) => this.$t(`${label}.label`)).join(' ou ');
  }

  async mounted() {
    this.hasError = await this.checkError();
  }

  async checkError() {
    if (this.item.type === 'job') {
      const job = this.getJob(this.item.uuid);
      if (job && job.classification) {
        const classification = await this.jobClassificationService.get(job.classification);
        return classification.deletedAt !== null;
      }
    }

    return false;
  }

  getAllowedList<T extends FirmListHandler>(list: Array<T>): Array<T> {
    return list.filter((item) => {
      const { handler } = item;

      return (typeof handler === 'function' && handler(this.level, this.item)) || (typeof handler === 'boolean' && handler);
    });
  }

  // No used from PADOA
  selectedEvent(data: FirmListItem) {
    if (this.isSelectable && !this.isSelected) {
      ListBus.$emit('select', data);
    }
  }

  dragStart() {
    ListBus.$emit('dragStart', this.item);
  }

  dragEnd() {
    ListBus.$emit('dragEnd');
  }

  dropEvent(data: any) {
    data.parent = this.item.uuid;
    ListBus.$emit('drop', data);
  }

  movedEvent(data: any) {
    data.type = this.item.type;
    data.parent = this.parent;
    ListBus.$emit('moved', data);
  }

  handleAction(command: any) {
    const { index } = command;
    const action = this.allowedActions[index];

    ListBus.$emit('command', action.action, command);
  }

  onDragStart(item: FirmListItem) {
    this.onDrag = item && this.allowedFirmTypes.includes(item.type);
  }

  onDragEnd() {
    this.onDrag = false;
  }

  created() {
    ListBus
      .$on('dragStart', this.onDragStart)
      .$on('dragEnd', this.onDragEnd);
  }

  beforeDestroy() {
    ListBus
      .$off('dragStart', this.onDragStart)
      .$off('dragEnd', this.onDragEnd);
  }
}
