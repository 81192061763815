

























import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import {
  FirmItem,
  FirmItemType,
  FirmItemUpdateForm,
  SelectableItem,
} from '@/types/firm/firm';
import { namespace } from 'vuex-class';

import Job from '@modules/declaration/entities/Job';
import Employee from '@modules/declaration/entities/Employee';
import Unit from '@modules/declaration/entities/Unit';

import FirmItemBus from '@/bus/FirmItemBus';

import NotSavedModal from './NotSavedModal.vue';
import UpdateEmployee from '../form/UpdateEmployeeForm.vue';
import UpdateJob from '../form/UpdateJobForm.vue';
import UpdateUnit from '../form/UpdateUnitForm.vue';

const classes = { job: Job, employee: Employee, unit: Unit };

const firmUpdateModule = namespace('firmUpdate');

@Component({
  components: {
    NotSavedModal,
    unit: UpdateUnit,
    job: UpdateJob,
    employee: UpdateEmployee,
  },
})
export default class FirmItemUpdateModal extends Vue {
  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @firmUpdateModule.Getter('getItem')
  getItem!: (uuid: string, type: string) => Unit | Job | Employee;

  selectedItem: FirmItem | null = null;
  updateForm: string = '';
  isNew: boolean = false;
  showConfirm: boolean = false;
  showModal: boolean = false;

  $refs!: {
    form: FirmItemUpdateForm
  };

  addItem(item: FirmItemType | FirmItem) {
    this.isNew = true;
    this.selectedItem = typeof item === 'string' ? new classes[item]() : item;
    FirmItemBus.selectedItem = this.selectedItem;
  }

  selectItem(item: SelectableItem) {
    const { type, uuid } = item;

    this.isNew = false;
    this.selectedItem = this.getItem(uuid, `${type}s`);
  }

  @Watch('selectedItem')
  selectUpdateForm(val: FirmItem | null) {
    this.showModal = val !== null;
    if (val !== null) {
      this.updateForm = val.type;
    }
  }

  checkBeforeClose(): boolean {
    const firmItemUpdateForm = this.$refs.form as FirmItemUpdateForm | undefined;
    if (firmItemUpdateForm && firmItemUpdateForm.isModify) {
      this.showConfirm = true;
      return false;
    }

    return true;
  }

  close() {
    this.selectedItem = null;
    FirmItemBus.$emit('unselectItem');
  }

  confirmClose() {
    if (this.checkBeforeClose()) {
      this.close();
    }
  }

  remove() {
    FirmItemBus.$emit('deleteItem', this.selectedItem);
    this.close();
  }

  created() {
    window.addEventListener('beforeunload', this.checkBeforeClose);

    FirmItemBus
      .$on('addItem', this.addItem)
      .$on('selectItem', this.selectItem);
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.checkBeforeClose);
    //   FirmItemBus
    //     .$off('addItem', this.addItem)
    //     .$off('selectItem', this.selectItem);
  }
}
