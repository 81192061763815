import { slice } from 'lodash';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class PdfBuilderService {
  public generateRecap(employees: Array<any>, filters: Record<string, any>, firm : string) {
    let content = [];

    content.push({
      text: `Récapitulatif (${firm})`,
      alignment: 'center',
      fontSize: 25,
      color: '#f7a840',
      margin: [0, 3],
    });

    content.push({
      text: `Généré le ${new Date().toLocaleDateString()}`,
      alignment: 'center',
      fontSize: 11,
      color: '#9f9f9f',
      margin: [2, 7],
    });

    content = [...content, ...this.getReacapFilters(filters)];

    content.push(this.getRecapTable(employees));

    pdfMake.tableLayouts = {
      wdLayout: {
        hLineWidth(i: Number, node: any) {
          return 1;
        },
        vLineWidth(i: Number) {
          return 1;
        },
        vLineColor(i: Number) {
          return '#aaa';
        },
        hLineColor(i: Number) {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft(i: Number) {
          return 0;
        },
        paddingRight(i: Number, node: any) {
          return 0;
        },
        fillColor(row: any, col: any, node: any) {
          return row > 0 && row % 2 ? '#f2f2f2' : null;
        },
      },
    };

    const docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [20, 30, 20, 30],
      content,
      styles: this.generateStyle(),
      footer: (currentPage: number, pageCount: number) => ({
        margin: 10,
        columns: [
          {
            fontSize: 9,
            text: [
              {
                text: `${currentPage.toString()}/${pageCount}`,
                margin: [0, 20],
              },
            ],
            alignment: 'center',
          },
        ],
      }),
    };

    pdfMake.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).download(); // If you want download
  }

  private generateStyle() {
    return {
      header: {
        fontSize: 8,
        alignment: 'center',
      },
      rowStyle: {
        alignment: 'left',
        fontSize: 8,
        margin: [3, 0, 3, 0],
      },
    };
  }

  private getReacapFilters(filters: Record<string, any>): Array<any> {
    const ulData : any[] = [];

    if (filters.fullName !== '') {
      ulData.push(`Nom prénom : ${filters.fullName}`);
    }

    if (filters.dateOfBirth !== '') {
      ulData.push(`Naissance' : ${filters.dateOfBirth}`);
    }

    if (filters.numSecu !== '') {
      ulData.push(`N° Sécurité Social : ${filters.numSecu}`);
    }

    if (filters.unit !== '') {
      ulData.push(`Unité(s) : ${filters.unit}`);
    }

    if (filters.job !== '') {
      ulData.push(`Postes : ${filters.job}`);
    }

    if (filters.sir !== '') {
      ulData.push(`SIR : ${filters.sir}`);
    }

    if (filters.sirMotif !== '') {
      ulData.push(`Motifs : ${filters.sirMotif}`);
    }

    if (filters.beginDate !== '') {
      ulData.push(`Entrée le : ${filters.beginDate}`);
    }

    if (filters.endDate !== '') {
      ulData.push(`Sortie le : ${filters.endDate}`);
    }

    if (ulData.length > 0) {
      return [
        {
          text: 'Filtres : ', fontSize: 13, bold: true, margin: [0, 1],
        },
        { ul: ulData, margin: [0, 5] },
      ];
    }

    return [];
  }

  private getRecapTable(employees: Array<any>) {
    // Group employee obj by unit and job
    const employeesData : any[] = [];

    employees.forEach((employee) => {
      const employeeKey = employee.fullName + employee.dateOfBirth + employee.numSecu;

      if (employeesData[employeeKey] === undefined) {
        const emp = {
          beginDate: this.convertDate(employee.beginDate),
          dateOfBirth: this.convertDate(employee.dateOfBirth),
          endDate: this.convertDate(employee.endDate),
          fullName: employee.fullName,
          job: employee.job,
          nbRow: employee.nbRow,
          nbRowUnit: employee.nbRowUnit,
          numSecu: employee.numSecu,
          sir: employee.sir,
          sirMotif: employee.sirMotif,
          unit: employee.unit,
          unitUuid: employee.unitUuid,
        };

        employeesData[employeeKey] = [];
        employeesData[employeeKey].employee = emp;
        employeesData[employeeKey].units = [];
      }

      if (employee.unitUuid !== undefined && employee.job !== undefined && employee.unitUuid !== '' && employee.job !== '') {
        if (employeesData[employeeKey].units[employee.unitUuid] !== undefined) {
          employeesData[employeeKey].units[employee.unitUuid].jobs.push(employee.job);
        } else {
          employeesData[employeeKey].units[employee.unitUuid] = [];
          employeesData[employeeKey].units[employee.unitUuid].jobs = [];
          employeesData[employeeKey].units[employee.unitUuid].unit = employee.unit;
          employeesData[employeeKey].units[employee.unitUuid].jobs.push(employee.job);
        }
      }
    });

    const rows : any[] = [];

    const header = [
      { text: 'Nom prénom', style: 'header' },
      { text: 'Date de naissance', style: 'header' },
      // { text: 'N° Sécurité Social', bold: true }, // Remove SECU
      { text: 'Unité(s)', style: 'header' },
      { text: 'Postes', style: 'header' },
      { text: 'SIR', style: 'header' },
      { text: 'Motifs', style: 'header' },
      { text: 'Entrée le', style: 'header' },
      { text: 'Sortie le', style: 'header' },
    ];

    rows.push(header);

    // Build array
    for (let i = 0; i < Object.keys(employeesData).length; i += 1) {
      // @ts-ignore
      const employee = employeesData[Object.keys(employeesData)[i]];

      if (Object.keys(employee.units).length === 0) {
        rows.push([
          { text: employee.employee.fullName, style: 'rowStyle' },
          { text: employee.employee.dateOfBirth, style: 'rowStyle', alignment: 'center' },
          // { text: employee.employee.numSecu, style: 'rowStyle' }, // Remove SECU
          '',
          '',
          { text: employee.employee.sir === 0 ? '' : 'Oui', style: 'rowStyle', alignment: 'center' },
          { text: employee.employee.sirMotif, style: 'rowStyle' },
          { text: employee.employee.beginDate, style: 'rowStyle', alignment: 'center' },
          { text: employee.employee.endDate, style: 'rowStyle', alignment: 'center' },
        ]);
      } else {
        let nbJob = 0;

        for (let j = 0; j < Object.keys(employee.units).length; j += 1) {
          const unit = employee.units[Object.keys(employee.units)[j]];
          if (unit.jobs !== undefined) {
            nbJob += unit.jobs.length;
          }
        }

        const firstUnitsKey = Object.keys(employee.units)[0];
        // First line
        rows.push([
          { rowSpan: nbJob, text: employee.employee.fullName, style: 'rowStyle' },
          {
            rowSpan: nbJob, text: employee.employee.dateOfBirth, style: 'rowStyle', alignment: 'center',
          },
          // { rowSpan: nbJob, text: employee.employee.numSecu, style: 'rowStyle' }, // Remove SECU
          { rowSpan: employee.units[firstUnitsKey].jobs.length, text: employee.units[firstUnitsKey].unit, style: 'rowStyle' },
          { text: employee.units[firstUnitsKey].jobs[0], style: 'rowStyle' },
          {
            rowSpan: nbJob,
            text: employee.employee.sir === 0 ? '' : 'Oui',
            style: 'rowStyle',
            alignment: 'center',
          },
          { rowSpan: nbJob, text: employee.employee.sirMotif, style: 'rowStyle' },
          {
            rowSpan: nbJob, text: employee.employee.beginDate, style: 'rowStyle', alignment: 'center',
          },
          {
            rowSpan: nbJob, text: employee.employee.endDate, style: 'rowStyle', alignment: 'center',
          },
        ]);

        // All jobs of first unit
        for (let j = 1; j < employee.units[firstUnitsKey].jobs.length; j += 1) {
          rows.push([
            '',
            '',
            // '', // Remove SECU
            '',
            { text: employee.units[firstUnitsKey].jobs[j], style: 'rowStyle' },
            '',
            '',
            '',
            '',
          ]);
        }

        // Rest of unit
        for (let j = 1; j < Object.keys(employee.units).length; j += 1) {
          const unit = employee.units[Object.keys(employee.units)[j]];
          rows.push([
            '',
            '',
            // '', // Remove SECU
            { rowSpan: unit.jobs.length, text: unit.unit, style: 'rowStyle' },
            { text: unit.jobs[0], style: 'rowStyle' },
            '',
            '',
            '',
            '',
          ]);

          // Jobs of unit
          for (let k = 1; k < unit.jobs.length; k += 1) {
            rows.push([
              '',
              '',
              // '', // Remove SECU
              '',
              { text: unit.jobs[k], style: 'rowStyle' },
              '',
              '',
              '',
              '',
            ]);
          }
        }
      }
    }

    return {
      layout: 'wdLayout',
      table: {
        headerRows: 1,
        widths: ['auto', 50, '*', '*', 30, '*', 50, 50],
        body: rows,
      },
    };
  }

  private convertDate(date: string) {
    if (date === undefined || date === null || date === '') return date;

    const dateParts = date.split(' ');
    let day = dateParts[0];
    if (day.length === 1) day = `0${day}`;
    const year = dateParts[2];
    const month = this.getMonthFromString(dateParts[1]);

    return `${day}/${month}/${year}`;
  }

  private getMonthFromString(month: string) {
    switch (month) {
      case 'janvier':
        return '01';
      case 'février':
        return '02';
      case 'mars':
        return '03';
      case 'avril':
        return '04';
      case 'mai':
        return '05';
      case 'juin':
        return '06';
      case 'juillet':
        return '07';
      case 'août':
        return '08';
      case 'septembre':
        return '09';
      case 'octobre':
        return '10';
      case 'novembre':
        return '11';
      case 'décembre':
        return '12';
      default:
        return month;
    }
  }
}
