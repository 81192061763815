




import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import {
  FirmItem,
  FirmItemType,
  FirmStructureItem,
  SelectableItem,
} from '@/types/firm/firm';

import Firm from '@models/firm/Firm';
import Unit from '@modules/declaration/entities/Unit';
import Job from '@modules/declaration/entities/Job';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import Employee from '@modules/declaration/entities/Employee';

import ListBus from '@/bus/ListBus';
import FirmItemBus from '@/bus/FirmItemBus';

import moment from 'moment';
import {
  FirmListItem,
  FirmListType,
  IListItem,
  IListItemDrop,
  IListItemMoved,
} from '../../types/firm-list';

import * as ActionStrategy from '../../utils/strategy/action-strategy';
import FirmUtils from '../../utils/FirmUtils';

const strategyActions: Array<ActionStrategy.IActionStrategy> = [
  new ActionStrategy.MoveJobAction(),
  new ActionStrategy.AddJobAction(),
  new ActionStrategy.AddUnitAction(),
  new ActionStrategy.AddEmployeeAction(),
  new ActionStrategy.SelectItemAction(),
  new ActionStrategy.DeleteItemAction(),
  new ActionStrategy.AssignmentEmployeeAction(),
];

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class AbstractStep extends Vue {
  @Getter('currentFirm')
  firm!: Firm;

  @firmUpdateModule.Getter('getUnit')
  getUnit!: (uuid: string) => Unit;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  @firmUpdateModule.Getter('getEmployeeHasJob')
  getEmployeeHasJob!: (uuid: string) => EmployeeHasJob;

  @firmUpdateModule.Getter('getCurrentEmployeeHasJobsByJob')
  getEmployeeHasJobsByJob!: (jobUuid: string) => EmployeeHasJob[];

  @firmUpdateModule.Getter('getEmployeeWithPerson')
  getEmployeeWithPerson!: (uuid: string) => Employee;

  @firmUpdateModule.Getter('getItem')
  getItem!: (uuid: string, type: string) => Unit | Job;

  @firmUpdateModule.Getter('getRootCurrentFirm')
  getRootUnit!: Unit | null;

  @firmUpdateModule.Action('addEmployeeHasJob')
  addEmployeeHasJob!: (employeeHasJob: EmployeeHasJob) => Promise<EmployeeHasJob>;

  @firmUpdateModule.Action('changeParentUnit')
  changeParentUnit!: (payload: { item: FirmStructureItem, parentUuid: string }) => Promise<any>;

  @firmUpdateModule.Action('deleteItem')
  deleteItem!: (item: SelectableItem) => Promise<any>;

  @firmUpdateModule.Action('deleteJob')
  deleteJob!: (payload: { uuid: string, transfer?: Job, cascade?: boolean }) => Promise<any>;

  @firmUpdateModule.Action('updateJob')
  updateJob!: (job : Job) => Promise<any>;

  @firmUpdateModule.Action('deleteEmployeeHasJob')
  deleteEmployeeHasJob!: (employeeHasJob: EmployeeHasJob) => Promise<void>;

  itemToDelete: SelectableItem | null = null;
  itemToMove: SelectableItem | null = null;
  selectedJob: SelectableItem | null = null;
  selectedItem: SelectableItem | null = null;
  showModalSupp: boolean = false;
  showModalSuppTr: boolean = false;
  showModalAssignment: boolean = false;
  showModalMoveJob: boolean = false;

  get firmListTypes(): Array<FirmListType> {
    return [];
  }

  get listTypes(): Array<FirmItemType> {
    return this.firmListTypes.map(firmListType => firmListType.type);
  }

  get list(): Array<FirmListItem> {
    if (!this.firm) {
      return [];
    }

    const unitRoot: Unit | null = this.getRootUnit;
    if (!unitRoot) {
      return [];
    }

    const root = {
      type: FirmItemType.Unit,
      uuid: this.firm.id.toString(),
      label: this.firm.label,
      children: [],
    };

    this.initList(root.children, [unitRoot], this.listTypes);
    return [root];
  }

  private initList(list: Array<FirmListItem>, children: Array<FirmStructureItem>, listType: Array<FirmItemType>) {
    children.forEach((child) => {
      if (listType.length > 0 && listType.indexOf(child.type) === -1) {
        return;
      }

      const item = {
        id: child.uuid,
        type: child.type,
        uuid: child.uuid,
        label: child.label,
        remove: child.remove,
        children: [],
        isOldJob: child.type === 'job' ? this.getJob(child.uuid).isOldJob : false,
      } as FirmListItem;

      if (child.type === FirmItemType.Unit) {
        item.children = [];

        const unit = child as Unit;
        const unitChildren: Unit[] = [];
        unit.childrenUuid.forEach((unitUuid) => {
          unitChildren.push(this.getUnit(unitUuid));
        });

        const unitJobs: Job[] = [];
        unit.jobsUuid.forEach((jobUuid) => {
          unitJobs.push(this.getJob(jobUuid));
        });

        this.initList(item.children, unitChildren, listType);
        this.initList(item.children, unitJobs, listType);
      } else if (child.type === FirmItemType.Job && (listType.indexOf(FirmItemType.Employee) !== -1 || listType.length === 0)) {
        item.children = [];

        const job = child as Job;
        const jobEhj: EmployeeHasJob[] = [];
        job.employeeHasJobsUuid.forEach((ehjUuid) => {
          jobEhj.push(this.getEmployeeHasJob(ehjUuid));
        });

        this.initEmployees(item.children, jobEhj);
      }

      list.push(item);
    });

    list.sort(FirmUtils.sortItem);
  }

  private initEmployees(list: Array<FirmListItem>, children: Array<EmployeeHasJob>) {
    children.forEach((employeeHasJob) => {
      const { uuid } = employeeHasJob;

      if (employeeHasJob.endDate !== null && moment(employeeHasJob.endDate).isBefore(moment())) {
        return;
      }

      const employee = this.getEmployeeWithPerson(employeeHasJob.employeeUuid);
      if (!employee) {
        return;
      }

      const item = {
        id: employee.uuid,
        type: FirmItemType.Employee,
        uuid: employee.uuid,
        label: employee.label,
        ruuid: uuid,
      };
      list.push(item);
    });

    list.sort(FirmUtils.sortItem);
  }

  checkEmployeeBeforeDelete(e: Event, data: FirmListItem) {
    e.stopPropagation();

    const job = data as Job;

    const employeeHasJobs = this.getEmployeeHasJobsByJob(job.uuid);
    data.children = employeeHasJobs.map(employeeHasJob => ({
      type: FirmItemType.Employee,
      uuid: employeeHasJob.employeeUuid,
      label: this.getEmployeeWithPerson(employeeHasJob.employeeUuid).label,
      ruuid: employeeHasJob.uuid,
    }));
    if (data.children.length) {
      this.itemToDelete = data;
      this.showModalSuppTr = true;
    } else {
      this.confirmDeleteItem(e, data);
    }
  }

  confirmDeleteItem(e: Event, data: FirmListItem) {
    e.preventDefault();
    e.stopPropagation();

    this.itemToDelete = data;
    this.showModalSupp = true;
  }

  validMoveJob(result: any): any {
    const { selectedUnit, job } = result;
    job.unitUuid = selectedUnit;
    this.updateJob(job);
    return null;
  }

  validDeleteJob(selectedJob: Job): Promise<any> {
    if (this.itemToDelete !== null) {
      return this.deleteJob({
        uuid: this.itemToDelete.uuid,
        transfer: selectedJob,
      }).then((response) => {
        this.itemToDelete = null;
        this.showModalSuppTr = false;
        return response;
      });
    }

    return Promise.reject(new Error('no item to delete'));
  }

  validDeleteItem() {
    if (this.itemToDelete !== null) {
      return this.deleteItem(this.itemToDelete).then((response) => {
        this.itemToDelete = null;
        this.showModalSupp = false;
        return response;
      });
    }

    return Promise.reject(new Error('no item to delete'));
  }

  validAssignment(payload: { selectedEmployees: Array<string>, beginDate: Date }) {
    const { selectedEmployees, beginDate } = payload;
    if (!this.selectedJob || selectedEmployees.length === 0) {
      return;
    }

    const { selectedJob } = this;
    selectedEmployees.forEach((v) => {
      const employeeHasJob = new EmployeeHasJob();
      employeeHasJob.employeeUuid = v;
      employeeHasJob.jobUuid = selectedJob.uuid;
      employeeHasJob.beginDate = beginDate;

      this.addEmployeeHasJob(employeeHasJob).then(() => {
        this.showModalAssignment = false;
      });
    });

    this.selectedJob = null;
  }

  addItem(e: Event, item: FirmItem) {
    e.preventDefault();
    e.stopPropagation();

    FirmItemBus.$emit('addItem', item);
  }

  selectItem(e: Event, item: SelectableItem): void {
    e.preventDefault();
    e.stopPropagation();

    this.selectedItem = item;
    FirmItemBus.$emit('selectItem', item);
  }

  async handleMoved(e: Event, data: IListItemMoved) {
    const {
      index,
      list,
      draggable,
      type,
    } = data;

    const promises = [];
    if (type === 'employee' && draggable.ruuid) {
      const employeeHasJob = this.getEmployeeHasJob(draggable.ruuid);
      promises.push(this.deleteEmployeeHasJob(employeeHasJob));
    }

    Promise.all(promises).then(() => {
      list.splice(index, 1);
    });
  }

  async handleDrop(e: Event, data: IListItemDrop) {
    const {
      list,
      item,
      parent,
      type,
    } = data;

    if (type === FirmItemType.Employee) {
      let employeeHasJob = new EmployeeHasJob();
      employeeHasJob.employeeUuid = item.uuid;
      employeeHasJob.jobUuid = parent;

      employeeHasJob = await this.addEmployeeHasJob(employeeHasJob);
      item.ruuid = employeeHasJob.uuid;
    } else {
      const structure = item.type === FirmItemType.Unit ? this.getUnit(item.uuid) : this.getJob(item.uuid);
      if (structure) {
        await this.changeParentUnit({ item: structure, parentUuid: parent });
      }
    }

    list.splice(0, 0, item);
    list.sort(FirmUtils.sortItem);
  }

  onSelect(data: FirmListItem) {
    this.selectItem(new Event('select'), data);
  }

  onMoved(data: IListItemMoved) {
    this.handleMoved(new Event(data.event), data);
  }

  onDrop(data: IListItemDrop) {
    this.handleDrop(data.event, data);
  }

  onCommand(action: string, data: IListItem) {
    strategyActions.forEach((strategyAction) => {
      if (strategyAction.check(action)) {
        strategyAction.action(new Event(action), data);
      }
    });
  }

  onDeleteItem(data: FirmItem) {
    // if (data.type === FirmItemType.Job) {
    //   this.checkEmployeeBeforeDelete(new Event('deleteItem'), data);
    // } else {
    this.confirmDeleteItem(new Event('deleteItem'), data);
    // }
  }

  onUnselectItem() {
    this.selectedItem = null;
  }

  onOpenModelAssignment(data: FirmItem) {
    this.selectedJob = data;
    this.showModalAssignment = true;
  }

  onMoveJob(data: FirmItem) {
    this.itemToMove = data;
    this.showModalMoveJob = true;
  }


  created() {
    ListBus
      .$on('select', this.onSelect)
      .$on('moved', this.onMoved)
      .$on('drop', this.onDrop)
      .$on('command', this.onCommand);

    FirmItemBus
      .$on('deleteItem', this.onDeleteItem)
      .$on('unselectItem', this.onUnselectItem)
      .$on('openModelAssignment', this.onOpenModelAssignment)
      .$on('moveJob', this.onMoveJob);
  }

  destroyed() {
    ListBus
      .$off('select', this.onSelect)
      .$off('moved', this.onMoved)
      .$off('drop', this.onDrop)
      .$off('command', this.onCommand);

    FirmItemBus
      .$off('deleteItem', this.onDeleteItem)
      .$off('unselectItem', this.onUnselectItem)
      .$off('openModelAssignment', this.onOpenModelAssignment);
  }
}
