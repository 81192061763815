import FirmItemBus from '@/bus/FirmItemBus';

import Unit from '@modules/declaration/entities/Unit';
import Job from '@modules/declaration/entities/Job';
import Employee from '@modules/declaration/entities/Employee';

import { IListItem } from '../../types/firm-list';

export interface IActionStrategy {
  check(action: string): boolean;

  action(e: Event, data: IListItem): void;
}

export class AddUnitAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'addUnit';
  }

  action(e: Event, data: IListItem): void {
    const { item, root } = data;

    const object = new Unit();
    object.firmId = parseInt(root.uuid, 10);
    object.parentUuid = item.type === 'unit' ? item.uuid : null;

    FirmItemBus.$emit('addItem', object);
  }
}

export class AddJobAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'addJob';
  }

  action(e: Event, data: IListItem): void {
    const { item, root } = data;

    const object = new Job();
    object.unitUuid = item.uuid;
    object.firmId = parseInt(root.uuid, 10);

    FirmItemBus.$emit('addItem', object);
  }
}

export class SelectItemAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'selectItem';
  }

  action(e: Event, data: IListItem): void {
    FirmItemBus.$emit('selectItem', data.item);
  }
}

export class DeleteItemAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'deleteItem';
  }

  action(e: Event, data: IListItem): void {
    FirmItemBus.$emit('deleteItem', data.item);
  }
}

export class AssignmentEmployeeAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'openModelAssignment';
  }

  action(e: Event, data: IListItem): void {
    FirmItemBus.$emit('openModelAssignment', data.item);
  }
}


export class MoveJobAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'moveJob';
  }

  action(e: Event, data: IListItem): void {
    FirmItemBus.$emit('moveJob', data.item);
  }
}

export class AddEmployeeAction implements IActionStrategy {
  check(action: string): boolean {
    return action === 'addEmployee';
  }

  action(e: Event, data: IListItem): void {
    const { root } = data;

    const object = new Employee();
    object.firmId = parseInt(root.uuid, 10);

    FirmItemBus.$emit('addItem', object);
  }
}
