




























import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import Vddl from 'vddl';

import Layout from '@/layouts/LayoutSideBar.vue';
// import StepBar from '@/components/utils/StepBar.vue';
import SideBar from '@/components/utils/SideBar.vue';

import Firm from '@models/firm/Firm';
import Employee from '@modules/declaration/entities/Employee';
import { EmployeeSidebarConfig } from '@modules/declaration/types/sidebar';
import Step from '@/types/step';
import FirmItemBus from '@/bus/FirmItemBus';
import moment from 'moment';

import RequestSaveForm from '@/utils/RequestSaveForm';
import { PrintActionInterface } from '@/types/breadcrumb';
import PreventNav from '@/utils/PreventNav';
// import PdfBuilderService from '@/services/PdfBuilderService';
// import StepBus from '@/bus/StepBus';
import FirmItemUpdateModal from '../components/modal/FirmItemUpdateModal.vue';
import EmployeeStep from '../components/step/EmployeeStep.vue';
import SummaryStep from '../components/step/SummaryStep.vue';

const NotCompleteModal = () => import('../components/modal/NotCompleteModal.vue');
const SelectEmployeeModal = () => import('../components/modal/SelectEmployeeModal.vue');
const LeavingEmployeeModal = () => import('../components/modal/LeavingEmployeeModal.vue');

const firmUpdateModule = namespace('firmUpdate');

Vue.use(Vddl);

const defaultSideBarConfig: EmployeeSidebarConfig = {
  selectable: false,
  allowAdd: false,
  showAlert: false,
  showAffectAll: false,
};

@Component({
  components: {
    Layout,
    // StepBar,
    SideBar,
    EmployeeStep,
    SummaryStep,
    FirmItemUpdateModal,
    SelectEmployeeModal,
    LeavingEmployeeModal,
    NotCompleteModal,
  },
})
@PreventNav({
  preventMsg: 'Vous avez des données modifiées non sauvegardées. Voulez-vous vraiment quitter la page ?',
})
export default class MyEmployeesListView extends Vue {
  @Getter('currentFirm')
  currentFirm !: Firm;

  @firmUpdateModule.Getter('getNotAffectedEmployees')
  getNotAffectedEmployees!: (id: number) => Array<Employee>;

  @firmUpdateModule.Getter('getEmployeeWithoutSS')
  getEmployeeWithoutSS!: (id: number) => Array<Employee>;

  @firmUpdateModule.Action('saveFirm')
  saveFirm!: (id: number) => Promise<void>;

  @firmUpdateModule.Action('validUpdate')
  @firmUpdateModule.Action('validUpdate')
  validUpdate!: () => Promise<void>;

  @firmUpdateModule.Action('findData')
  getData!: (firmId: number) => Promise<any>;

  @firmUpdateModule.Action('clearData')
  clearDeclarationData!: () => Promise<any>;

  @firmUpdateModule.Getter('hasEditedUnitsCurrentFirm')
  editedUnits!: boolean;

  @firmUpdateModule.Getter('hasEditedJobsCurrentFirm')
  editedJobs!: boolean;

  @firmUpdateModule.Getter('hasEditedEmployeesCurrentFirm')
  editedEmployees!: boolean;

  @firmUpdateModule.Getter('hasRemovedUnitsCurrentFirm')
  removedUnits!: boolean;

  @firmUpdateModule.Getter('hasRemovedJobsCurrentFirm')
  removedJobs!: boolean;

  showModalConfirm: boolean = false;
  currentStep: number = 0;
  currentModal: string = '';
  showModal: boolean = false;
  showSSModal: boolean = false;
  currentModalOptions: Record<any, any> | null = null;

  modalValidAction: Record<any, any> = {
    NotCompleteModal: () => this.incrementStep(),
  };

  readonly employeeSideBarConfig: { [key: number]: EmployeeSidebarConfig } = [
    {
      selectable: true,
      allowAdd: true,
      showAlert: true,
      showAffectAll: false,
    },
  ];

  get employeesSynchronizedAt() {
    if (!this.currentFirm.employeesSynchronizedAt) {
      return 'Aucune (Synchronisation prévue dans 24h)';
    }

    return moment(this.currentFirm.employeesSynchronizedAt).format('DD/MM/YYYY HH:mm');
  }

  get employees(): Array<Employee> {
    return this.getNotAffectedEmployees(this.currentFirm.id);
  }

  get employeesWithoutSS(): Employee[] {
    return this.getEmployeeWithoutSS(this.currentFirm.id);
  }

  get steps(): Array<Step> {
    const firstStepAction = [];
    // const secondStepAction = [];

    if (this.isGranted('my-employees', 2)) {
      firstStepAction.push({
        label: 'Enregistrer',
        handler: this.validEmployeeAffected,
        condition: () => this.isModify,
      });

      // secondStepAction.push({
      //   label: 'Actualiser mes effectifs',
      //   handler: this.validEmployeeAffected,
      //   condition: () => this.isModify,
      // });
      //
      // secondStepAction.push({
      //   label: 'Exporter mon récapitulatif',
      //   handler: () => { window.open(this.getPdf(), '_blank'); },
      // });
      //
      // secondStepAction.push({
      //   label: 'Exporter mon récapitulatif filtré',
      //   handler: () => StepBus.$emit('printActionBtn'),
      // });
    }

    return [
      new Step('Affectez les salariés', 'EmployeeStep', true, firstStepAction),
      // new Step('Récapitulatif', 'SummaryStep', true, secondStepAction),
    ];
  }

  get hideSideBar(): boolean {
    return true;
    // return !(this.currentStep in this.employeeSideBarConfig);
  }

  get step() {
    return this.steps[this.currentStep];
  }

  get currentStepView() {
    return this.step.view;
  }

  get currentStepActions() {
    return this.step.actions;
  }

  get printAction(): PrintActionInterface | null {
    if (this.currentStep > 0 && !this.isModify) {
      return {
        href: this.getPdf(),
      };
    }

    return null;
  }

  get isLast() {
    return this.currentStep === this.steps.length - 1;
  }

  get isModify() {
    if (!this.currentFirm) {
      return false;
    }
    return this.editedUnits
      || this.editedJobs
      || this.editedEmployees
      || this.removedUnits
      || this.removedJobs;
  }

  get currentModalValidAction(): any {
    return this.modalValidAction[this.currentModal] !== undefined ? this.modalValidAction[this.currentModal] : null;
  }

  openModal(modal: string) {
    this.currentModal = modal;
    this.showModal = true;
  }

  addEmployee() {
    const object = new Employee();
    object.firmId = this.currentFirm.id;

    FirmItemBus.$emit('addItem', object);
  }

  validEmployeeAffected() {
    // if (this.getNotAffectedEmployees(this.currentFirm.id).length > 0) {
    //   this.currentModalOptions = {};
    //   this.currentModal = 'NotCompleteModal';
    //   this.showModal = true;
    //   return;
    // }

    // this.showModalConfirm = true;

    // Suppression de la modal #26864
    this.confirmSave();
  }

  confirmSave() {
    this.showModalConfirm = false;
    const loadingInstance = this.$loading({
      lock: true,
      text: 'Patienter quelques instants',
      customClass: 'loader--custom',
      background: 'rgba(0, 0, 0, 0.7)',
    });

    this.save().finally(() => {
      loadingInstance.close();
    });
  }

  @RequestSaveForm({
    validMsg: 'Vos données ont bien été transmises à OPSAT',
    errorMsg: 'Il y a eu une erreur lors de la sauvegarde. Veuillez réessayer.',
  })
  async save() {
    this.showModal = false;

    await this.saveFirm(this.currentFirm.id);
    await this.validUpdate();
    this.incrementStep();
  }

  validModal() {
    this.showModal = false;
    if (this.currentModalValidAction) {
      this.currentModalValidAction();
    }
  }

  incrementStep() {
    this.currentStep = 0;
  }

  handleAction(action: string) {
    if (action === 'addEmployee') {
      this.addEmployee();
    } else if (action === 'leavingEmployee') {
      this.openModal('LeavingEmployeeModal');
    } else if (action === 'updateEmployee') {
      this.openModal('SelectEmployeeModal');
    }
  }

  getPdf() {
    return this.generateEmpr1tePath('firm/download_summary');
  }

  created() {
    const loadingInstance = this.$loading({
      lock: true,
      text: 'Chargement de vos données, veuillez patienter...',
      customClass: 'loader--custom',
      background: 'rgba(0, 0, 0, 0.7)',
    });

    this.getData(this.currentFirm.id).finally(() => {
      loadingInstance.close();
      this.showSSModal = this.employeesWithoutSS.length > 0;

      if (typeof this.$route.query.action === 'string' && this.$route.query.action) {
        this.handleAction(this.$route.query.action);
      }
    });
  }

  beforeDestroy() {
    this.clearDeclarationData();
  }
}
