















import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import Vddl from 'vddl';

import Layout from '@/layouts/LayoutSideBar.vue';

import Firm from '@models/firm/Firm';

import { PrintActionInterface } from '@/types/breadcrumb';
import FirmItemUpdateModal from '../components/modal/FirmItemUpdateModal.vue';
import EmployeeStep from '../components/step/EmployeeStep.vue';

const firmUpdateModule = namespace('firmUpdate');

Vue.use(Vddl);

@Component({
  components: {
    Layout,
    EmployeeStep,
    FirmItemUpdateModal,
  },
})
export default class OrganizationView extends Vue {
  @Getter('currentFirm')
  currentFirm !: Firm;

  @firmUpdateModule.Action('findData')
  getData!: (firmId: number) => Promise<any>;

  @firmUpdateModule.Action('clearData')
  clearDeclarationData!: () => Promise<any>;

  get printAction(): PrintActionInterface | null {
    return {
      handler: () => this.getPdf(),
    };
  }

  created() {
    const loadingInstance = this.$loading({
      lock: true,
      text: 'Chargement de vos données, veuillez patienter...',
      customClass: 'loader--custom',
      background: 'rgba(0, 0, 0, 0.7)',
    });

    this.getData(this.currentFirm.id).finally(() => {
      loadingInstance.close();
    });
  }

  beforeDestroy() {
    this.clearDeclarationData();
  }

  getPdf() {
    window.redirectToEmpr1te('firm/download_organization');
  }
}
