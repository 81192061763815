
























































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import moment from 'moment';
import _ from 'lodash';

import { FirmItemUpdateForm } from '@/types/firm/firm';
import { VForm } from '@/types/v-form';
import { PersonSexEnumName } from '@/types/firm/person';

import Exposition from '@models/firm/Exposition';
import Employee from '@modules/declaration/entities/Employee';
import Person from '@modules/declaration/entities/Person';
import EmployeeHasExposition from '@modules/declaration/entities/EmployeeHasExposition';

import * as Rules from '@/utils/CustomFormRules';

import FirmItemBus from '@/bus/FirmItemBus';
import Timeout from '@/utils/Timeout';
import { getDeclarationData } from '@modules/declaration/requests/declarationRequest';
import Firm from '@models/firm/Firm';
import EmployeeHasContractForm from './EmployeeHasContractForm.vue';
import EmployeeHasJobsForm from './EmployeeHasJobsForm.vue';

const firmModule = namespace('firm');
const firmUpdateModule = namespace('firmUpdate');

@Component({
  components: {
    EmployeeHasContractForm,
    EmployeeHasJobsForm,
  },
})
export default class UpdateEmployeeForm extends Vue implements FirmItemUpdateForm {
  @Getter('currentFirm')
  currentFirm!: Firm;

  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Object })
  value!: Employee;

  @Prop({ type: Boolean, default: false })
  isNew!: boolean;

  @State(state => state.UserModule.currentFirmId)
  currentFirmId!: number;

  @firmModule.Getter('getEmployeeExpositions')
  expositions!: Array<Exposition>;

  @firmUpdateModule.Action('updateEmployeeInfo')
  updateEmployeeInfo!: (employee: Employee) => Promise<Employee>;

  @firmUpdateModule.Getter('getEmployeeWithInfos')
  getEmployeeWithInfos!: (uuid: string) => Employee | null;

  original: Employee | null = null;
  buffer: Employee = new Employee();
  activeCard: string = 'first';
  errorFields: string[] = [];
  disableSaveButton: boolean = false;
  selectedExpositions: number[] = [];
  personSexEnum = PersonSexEnumName;

  validNoSS = false;
  showConfirmNoSS = false;

  $refs!: {
    form: VForm
  };

  get rules(): any {
    return {
      'person.forename': [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
      ],
      'person.birthName': [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
      ],
      'person.sex': [
        { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
      ],
      'person.dateOfBirth': [
        {
          type: 'date',
          required: true,
          message: this.$t('form.required_field'),
          trigger: 'blur',
        },
        {
          type: 'date',
          message: 'Le salarié doit avoir au moins 12 ans à la date d\'entrée dans l\'entreprise',
          trigger: 'blur',
          validator: (rule: any, value: any, callback: (p?: any) => void): void => {
            const errors = [];
            if (value && value.getTime() > (moment(this.buffer.beginDate).subtract(12, 'years') as unknown as number)) {
              errors.push(new Error(rule.message));
            }

            callback(errors);
          },
          date: this.buffer.beginDate,
        },
      ],
      'person.numSecu': [
        {
          required: true,
          validator: Rules.validateNumSecu,
          trigger: 'blur',
          messageLength: this.$t('form.num_secu.error_length'),
          messageChecksum: this.$t('form.num_secu.error_checksum'),
          personBirth: this.buffer.person.dateOfBirth,
          personSex: this.buffer.person.sex,
          messageCheckSecu: 'Les informations saisies ne sont pas en accord avec le numéro de sécurité sociale',
        },
        {
          required: false,
          validator: (rule: any, value: any, callback: (p?: any) => void) => {
            if (!this.isNew || this.validNoSS) {
              callback();
              return;
            }
            if (!value || value.length === 0) {
              callback(new Error(rule.message));
              return;
            }

            callback();
          },
          trigger: 'blur',
          message: this.$t('form.num_secu.required'),
        },
      ],
      beginDate: [
        {
          type: 'date',
          required: true,
          message: this.$t('form.required_field'),
          trigger: 'blur',
        },
      ],
      endDate: [
        {
          type: 'date',
          validator: Rules.dateGreaterThan,
          date: this.buffer.beginDate,
          message: this.$t('rules.firm_date_greater'),
          trigger: 'blur',
        },
        {
          type: 'date',
          validator: Rules.dateLessThan,
          date: new Date(),
          message: this.$t('rules.employee.not_future_date'),
          trigger: 'blur',
        },
      ],
    };
  }

  get isModify(): boolean {
    return !_.isEqual(this.original, this.buffer);
  }

  get errorPersonalData(): boolean {
    const personalFields = ['person.forename', 'person.birthName', 'person.sex', 'person.dateOfBirth', 'person.numSecu', 'beginDate', 'endDate'];
    return _.intersection(this.errorFields, personalFields).length > 0;
  }

  get errorJobs(): Array<any> {
    return this.errorFields.filter(index => index.includes('employeeHasJobs'));
  }

  get errorContracts(): Array<any> {
    return this.errorFields.filter(index => index.includes('employeeHasContracts'));
  }

  get isEditable() {
    return !this.value.isArchived && this.editable;
  }

  get alreadyInFirm(): boolean {
    return false;
  }

  get personIsSave(): boolean {
    return this.buffer.person && this.buffer.person.isSave;
  }

  @Watch('value', { immediate: true })
  onValueChange() {
    this.selectedExpositions = [];
    let { value } = this;
    if (value.uuid) {
      const employeeSave = this.getEmployeeWithInfos(value.uuid) as Employee | null;
      if (employeeSave) {
        value = employeeSave;
      }
    }

    if (!value.person) {
      value.person = new Person();
    }

    this.original = value;
    this.buffer = _.cloneDeep<Employee>(value);
    this.buffer.beginDate = moment(this.buffer.beginDate).startOf('day').toDate();
    this.selectedExpositions = this.original.expositions.filter(ehe => ehe.isCurrent).map(ehe => ehe.exposition);
  }

  sanitize() {
    this.buffer.person.forename = this.buffer.person.forename.trim().toUpperCase();
    this.buffer.person.surname = this.buffer.person.surname ? this.buffer.person.surname.trim().toUpperCase() : null;
    this.buffer.person.birthName = this.buffer.person.birthName.trim().toUpperCase();
    this.buffer.person.numSecu = this.buffer.person.numSecu ? this.buffer.person.numSecu.replace(/ /g, '') : null;

    const currentExposition = this.original ? this.original.expositions : [];
    this.buffer.expositions = this.selectedExpositions.map((expositionId) => {
      const index = currentExposition.findIndex(e => e.exposition === expositionId && (e.isCurrent || e.endDate === TODAY));
      let ehe;
      if (index === -1) {
        ehe = new EmployeeHasExposition();
        ehe.beginDate = TODAY;
        ehe.exposition = expositionId;
        ehe.employeeUuid = this.buffer.uuid;
      } else {
        ehe = _.cloneDeep(currentExposition[index]);
        currentExposition.splice(index, 1);
      }
      ehe.endDate = this.buffer.endDate || null;

      return ehe;
    });
  }

  rejoined() {
    /** Done this shit because of Axess T_T */
    const clonedPerson = new Person();
    clonedPerson.numSecu = this.buffer.person.numSecu;
    clonedPerson.birthName = this.buffer.person.birthName;
    clonedPerson.forename = this.buffer.person.forename;
    clonedPerson.surname = this.buffer.person.surname;
    clonedPerson.dateOfBirth = this.buffer.person.dateOfBirth;
    clonedPerson.sex = this.buffer.person.sex;
    clonedPerson.origin = this.buffer.person.uuid;

    const clonedEmployee = new Employee();
    clonedEmployee.beginDate = TODAY;
    clonedEmployee.person = clonedPerson;
    clonedEmployee.personUuid = clonedPerson.uuid;
    clonedEmployee.firmId = this.buffer.firmId;
    clonedEmployee.employeeHasJobs = [];
    clonedEmployee.employeeHasJobsUuid = [];
    clonedEmployee.employeeHasContracts = [];
    clonedEmployee.employeeHasContractsUuid = [];
    clonedEmployee.expositions = [];
    clonedEmployee.employeeHasExpositionsUuid = [];

    FirmItemBus.$emit('addItem', clonedEmployee);
  }

  validate(e: Event): Promise<any> {
    e.preventDefault();

    this.disableSaveButton = true;
    this.sanitize();
    if (!this.isModify && !this.isNew) {
      this.$emit('valid');
      return Promise.resolve();
    }

    let validForm = true;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    this.errorFields = Object.keys(errorFields);
    if (!validForm) {
      this.disableSaveButton = false;
      return Promise.reject(errorFields);
    }

    return this.save();
  }

  onChangeNoSS(val: boolean) {
    this.validNoSS = false;

    if (val) {
      this.showConfirmNoSS = true;
      return false;
    }

    return true;
  }

  confirmNoSS() {
    this.buffer.person.numSecu = null;
    this.validNoSS = true;
    this.showConfirmNoSS = false;
  }

  @Timeout()
  save(): Promise<any> {
    return this.updateEmployeeInfo(this.buffer).finally(() => {
      this.disableSaveButton = false;
      this.$emit('valid');
    });
  }
}
