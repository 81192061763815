













































































import _ from 'lodash';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';

import { SummaryRowInterface } from '@modules/declaration/types/summary';
import TemplateFilter from '@/templates/TemplateFilter.vue';

import StepBus from '@/bus/StepBus';

import Exposition from '@models/firm/Exposition';
import Employee from '@modules/declaration/entities/Employee';
import Job from '@modules/declaration/entities/Job';
import Unit from '@modules/declaration/entities/Unit';
import PdfBuilderService from '@/services/PdfBuilderService';

const firmUpdateModule = namespace('firmUpdate');

@Component({
  components: { TemplateFilter },
})

export default class SummaryView extends Vue {
  @State(state => state.UserModule.currentFirmId)
  currentFirmId!: number;

  @firmUpdateModule.Getter('getActiveEmployees')
  activeEmployees!: (id: number) => Array<Employee>;

  @firmUpdateModule.Getter('getUnit')
  getUnit!: (uuid: string) => Unit;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  @firmUpdateModule.Getter('getEmployeeWithInfos')
  getEmployeeInfo!: (uuid: string) => Employee;

  filters: Record<string, any> = {
    fullName: '',
    dateOfBirth: '',
    numSecu: '',
    unit: '',
    job: '',
    sir: '',
    sirMotif: '',
    beginDate: '',
    endDate: '',
  };

  sirChoices = {
    '': 'Tous',
    1: 'Oui',
    0: 'Non',
  };

  nbSIR: number = 0;
  nbEmployee1J: number = 0;
  nbCurrentEmployee: number = 0;
  year: string = moment().format('Y');
  limit: number = 10;
  page: number = 1;

  get orderedEmployees() {
    const employees = this.activeEmployees(this.currentFirmId);
    return _.orderBy(employees, ['label'], ['asc']);
  }

  get summary(): Array<SummaryRowInterface> {
    const rows: Array<SummaryRowInterface> = [];
    this.nbSIR = 0;
    this.nbEmployee1J = 0;
    this.nbCurrentEmployee = 0;
    const firstJanuary = moment(`${this.year}-01-01`);

    this.orderedEmployees.forEach((e) => {
      const employee = this.getEmployeeInfo(e.uuid);
      if (!employee) {
        return;
      }

      const { person } = employee;
      const sirMotif: Array<string> = [];

      if (employee.isActive) {
        employee.expositions.forEach((ehe) => {
          if (ehe.isCurrent) {
            const exposition = Exposition.find(ehe.exposition);
            if (exposition) {
              sirMotif.push(exposition.name);
            }
          }
        });
      }

      employee.activeJobs.forEach((employeeHasJob) => {
        const job = this.getJob(employeeHasJob.jobUuid);
        if (!job) {
          return;
        }

        job.expositions.forEach((id) => {
          const exposition = Exposition.find(id);
          if (exposition) {
            sirMotif.push(exposition.name);
          }
        });
      });

      if (sirMotif.length) {
        this.nbSIR += 1;
      }

      const beginDate = moment(employee.beginDate);
      const endDate = (employee.endDate) ? moment(employee.endDate) : null;

      const { numSecu, label } = person;
      const birthDate = (person.dateOfBirth) ? moment(person.dateOfBirth).format('D MMMM YYYY') : '';
      const sir = sirMotif.length !== 0 ? 1 : 0;
      const reasons = sirMotif ? sirMotif.join(', ') : '';
      const dateStart = beginDate ? beginDate.format('D MMMM YYYY') : '';
      const dateEnd = endDate ? endDate.format('D MMMM YYYY') : '';

      if (beginDate.isSameOrBefore(TODAY) && (endDate === null || endDate.isAfter(TODAY))) {
        this.nbCurrentEmployee += 1;
      }

      if (beginDate.isSameOrBefore(firstJanuary) && (endDate === null || endDate.isSameOrAfter(firstJanuary))) {
        this.nbEmployee1J += 1;
      }

      let rowEmployee: SummaryRowInterface | null = null;
      let rowEmployeeLastUnit: SummaryRowInterface | null = null;

      if (endDate && endDate.isSameOrBefore(TODAY)) {
        const row: SummaryRowInterface = {
          fullName: label,
          dateOfBirth: birthDate,
          numSecu: numSecu || '',
          unitUuid: '',
          unit: '',
          job: '',
          sir,
          sirMotif: reasons,
          beginDate: dateStart,
          endDate: dateEnd,
          nbRow: 1,
          nbRowUnit: 1,
        };

        rows.push(row);
      } else if (employee.activeJobs.length > 0) {
        employee.activeJobs.forEach((employeeHasJob) => {
          const job = this.getJob(employeeHasJob.jobUuid);
          let unit = null;
          if (job) {
            unit = this.getUnit(job.unitUuid);
          }

          const row: SummaryRowInterface = {
            fullName: label,
            dateOfBirth: birthDate,
            numSecu: numSecu || '',
            unitUuid: unit ? unit.uuid : '',
            unit: unit ? unit.label : 'Aucune unité',
            job: job ? job.label : 'Aucun poste',
            sir,
            sirMotif: reasons,
            beginDate: dateStart,
            endDate: dateEnd,
            nbRow: 0,
            nbRowUnit: 0,
          };
          if (rowEmployee === null) {
            rowEmployee = row;
          }
          rowEmployee.nbRow += 1;

          if (rowEmployeeLastUnit === null || rowEmployeeLastUnit.unitUuid !== row.unitUuid) {
            rowEmployeeLastUnit = row;
          }
          rowEmployeeLastUnit.nbRowUnit += 1;

          rows.push(row);
        });
      } else {
        const row: SummaryRowInterface = {
          fullName: label,
          dateOfBirth: birthDate,
          numSecu: numSecu || '',
          unitUuid: '',
          unit: 'Aucune unité',
          job: 'Aucun poste',
          sir,
          sirMotif: reasons,
          beginDate: dateStart,
          endDate: dateEnd,
          nbRow: 1,
          nbRowUnit: 1,
        };

        rows.push(row);
      }
    });

    return rows;
  }

  get filteredSummary(): Array<SummaryRowInterface> {
    return this.summary.filter((row) => {
      const searchFullName = !this.filters.fullName || row.fullName.toLowerCase().includes(this.filters.fullName.toLowerCase());
      const searchDateOfBirth = !this.filters.dateOfBirth || row.dateOfBirth.toLowerCase().includes(this.filters.dateOfBirth.toLowerCase());
      const searchNumSecu = !this.filters.numSecu || row.numSecu.toLowerCase().includes(this.filters.numSecu.toLowerCase());
      const searchUnit = !this.filters.unit || row.unit.toLowerCase().includes(this.filters.unit.toLowerCase());
      const searchJob = !this.filters.job || row.job.toLowerCase().includes(this.filters.job.toLowerCase());
      const searchSir = !this.filters.sir || row.sir === parseInt(this.filters.sir, 10);
      const searchSirMotif = !this.filters.sirMotif || row.sirMotif.toLowerCase().includes(this.filters.sirMotif.toLowerCase());
      const searchBeginDate = !this.filters.beginDate || row.beginDate.toLowerCase().includes(this.filters.beginDate.toLowerCase());
      const searchEndDate = !this.filters.endDate || row.endDate.toLowerCase().includes(this.filters.endDate.toLowerCase());

      return searchFullName && searchDateOfBirth && searchNumSecu && searchUnit && searchJob && searchSir && searchSirMotif && searchBeginDate && searchEndDate;
    });
  }

  rowSpanMethod(payload: { row: SummaryRowInterface, column: any, rowIndex: number, columnIndex: number }) {
    const { row, columnIndex } = payload;
    let rowSpan = 1;
    if (columnIndex === 3) {
      rowSpan = row.nbRowUnit;
    } else if (columnIndex !== 4) {
      rowSpan = row.nbRow;
    }

    return {
      rowspan: rowSpan,
      colspan: rowSpan ? 1 : 0,
    };
  }

  created() {
    StepBus.$on('printActionBtn', () => {
      const pdfBuilder = new PdfBuilderService();
      pdfBuilder.generateRecap(
        this.filteredSummary,
        this.filters,
        `${this.$store.getters.currentFirm.label} ${this.$store.getters.currentFirm.number}`,
      );
    });
  }
}
