import { createDecorator } from 'vue-class-component';

export interface ConfigPreventNav {
  preventMsg?: string,
}

export default function PreventNav(config?: ConfigPreventNav) {
  return createDecorator((options, key) => {
    const conf = config || {};
    if (options.computed !== undefined && options.computed.isModify !== undefined) {
      if (options.methods === undefined) {
        options.methods = {};
      }

      options.methods.preventNav = function preventNav(event: BeforeUnloadEvent) {
        if (!(this as any).isModify) {
          return;
        }

        event.preventDefault();
        event.returnValue = conf.preventMsg;
      };

      options.beforeRouteLeave = function beforeRouteLeave(to, from, next) {
        if ((this as any).isModify) {
          (this as any).$confirm(conf.preventMsg)
            .then(() => {
              next();
            })
            .catch(() => {
              next(false);
            });
        } else {
          next();
        }
      };

      options.beforeMount = function beforeMountPrevent() {
        window.addEventListener('beforeunload', (this as any).preventNav);
        (this as any).$once('hook:beforeDestroy', () => {
          window.removeEventListener('beforeunload', (this as any).preventNav);
        });
      };
    }
  });
}
