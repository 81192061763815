



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TreeSelect from '@riophae/vue-treeselect';

import { lazyInject } from '@/ioc/container';
import { FirmItemType } from '@/types/firm/firm';

import Job from '@modules/declaration/entities/Job';
import Unit from '@modules/declaration/entities/Unit';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';

import { FirmListServiceInterface } from '@modules/declaration/services/FirmListServiceInterface';

import * as Rules from '@/utils/CustomFormRules';

import SERVICE_IDENTIFIER from '../../ioc/identifiers';

const firmUpdateModule = namespace('firmUpdate');

@Component({
  components: {
    TreeSelect,
  },
})
export default class EmployeeHasJobsForm extends Vue {
  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Array })
  value!: EmployeeHasJob[];

  @Prop({ type: Boolean, default: true })
  showAdd!: boolean;

  @Prop({ type: String })
  employeeUuid!: string;

  @Prop({ type: Date, default: null })
  employeeBeginDate!: Date | null;

  @Prop({ type: Date, default: null })
  employeeEndDate!: Date | null;

  @Prop({ type: Array, default: () => [] })
  errors!: Array<any>;

  @firmUpdateModule.Getter('getRootWithChildrenCurrentFirm')
  getRootUnitWithChildren!: Unit | null;

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  @lazyInject(SERVICE_IDENTIFIER.FirmListServiceInterface)
  firmListService!: FirmListServiceInterface;

  selected: number = -1;
  selectedJob: Job | null = null;
  assignment: boolean = false;

  get rules(): Object {
    return {
      beginDate: [
        {
          type: 'date',
          required: true,
          message: this.$t('form.required_field'),
          trigger: 'blur',
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_greater'),
          validator: Rules.dateGreaterThan,
          date: this.employeeBeginDate,
          objects: this.value,
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_lower'),
          validator: Rules.dateLessThan,
          date: this.employeeEndDate,
          objects: this.value,
        },
      ],
      endDate: [
        {
          type: 'date',
          message: this.$t('rules.firm_date_greater'),
          validator: Rules.dateGreaterThan,
          date: this.employeeBeginDate,
          objects: this.value,
        },
        {
          type: 'date',
          message: this.$t('declaration.form_employee.job_end_date_message_error'),
          validator(rule: any, value: Date, callback: (p?: any) => void) {
            const matches = rule.fullField.match(/employeeHasJobs\.(\d)\.endDate/);
            if (matches && matches[1] !== undefined) {
              const index = parseInt(matches[1], 10);
              const { beginDate } = rule.object[index];

              if (value && value.getTime() < beginDate.getTime()) {
                callback(new Error(rule.message));
              }
            }

            callback();
          },
          object: this.value,
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_lower'),
          validator: Rules.dateLessThan,
          date: this.employeeEndDate,
          objects: this.value,
        },
      ],
    };
  }

  get list() {
    const root = this.getRootUnitWithChildren;
    if (!root) {
      return [];
    }

    return this.firmListService.convertStructureToList(root, [FirmItemType.Unit, FirmItemType.Job]);
  }

  get currentEmployeeHasJobs() {
    return this.value; // .filter(k => this.getJob(k.jobUuid).isActive);
  }

  getJobLabel(uuid: string) {
    const job = this.getJob(uuid);
    return job ? job.label : 'NC';
  }

  error(index: number): boolean {
    return !!this.errors.find(error => error.includes(`employeeHasJobs.${index}`));
  }

  add(selected: Job | null) {
    if (selected === null) {
      return;
    }

    const employeeHasJob = new EmployeeHasJob();
    employeeHasJob.employeeUuid = this.employeeUuid;
    employeeHasJob.jobUuid = selected.uuid;

    employeeHasJob.beginDate = this.value.length === 0 && this.employeeBeginDate ? this.employeeBeginDate : TODAY;
    this.value.push(employeeHasJob);
    this.$emit('add', employeeHasJob);

    this.selected = this.value.length - 1;
    this.selectedJob = null;
    this.assignment = false;
  }

  remove(uuid: string) {
    const index = this.value.findIndex(v => v.uuid === uuid);
    if (index > -1) {
      this.value.splice(index, 1);
      this.$emit('remove', index);
    }
  }
}
