import { createDecorator } from 'vue-class-component';

export default function Timeout(timeout: number = 0) {
  return createDecorator((options, key) => {
    if (options.methods) {
      const originalMethod = options.methods[key];

      options.methods[key] = function wrappedMethod(...args) {
        setTimeout(() => originalMethod.apply(this, args), timeout);
      };
    }
  });
}
