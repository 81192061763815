



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import EmployeeHasContract from '@modules/declaration/entities/EmployeeHasContract';
import Contract from '@models/firm/Contract';

import * as Rules from '@/utils/CustomFormRules';

const firmModule = namespace('firm');

@Component
export default class EmployeeHasContractForm extends Vue {
  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Array })
  value!: EmployeeHasContract[];

  @Prop({ type: Boolean, default: true })
  showAdd!: boolean;

  @Prop({ type: String })
  employeeUuid!: string;

  @Prop({ type: Date, default: null })
  employeeBeginDate!: Date | null;

  @Prop({ type: Date, default: null })
  employeeEndDate!: Date | null;

  @Prop({ type: Array, default: () => [] })
  errors!: Array<any>;

  @firmModule.Getter('getVisibleContracts')
  visibleContracts!: Array<Contract>;

  selected: number = -1;

  get rules(): Object {
    return {
      contractId: [
        {
          type: 'number',
          required: true,
          message: this.$t('form.required_field'),
          trigger: 'blur',
        },
      ],
      dateStart: [
        {
          type: 'date',
          required: true,
          message: this.$t('form.required_field'),
          trigger: 'blur',
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_greater'),
          validator: Rules.dateGreaterThan,
          date: this.employeeBeginDate,
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_lower'),
          validator: Rules.dateLessThan,
          date: this.employeeEndDate,
        },
      ],
      dateEnd: [
        {
          type: 'date',
          message: this.$t('rules.firm_date_greater'),
          validator: Rules.dateGreaterThan,
          date: this.employeeBeginDate,
        },
        {
          type: 'date',
          message: this.$t('declaration.form_employee.contract_end_date_message_error'),
          validator(rule: any, value: Date, callback: (p?: any) => void) {
            const matches = rule.fullField.match(/employeeHasContracts\.(\d)\.dateEnd/);
            if (matches && matches[1] !== undefined) {
              const index = parseInt(matches[1], 10);
              const { dateStart } = rule.object[index];

              if (value && value.getTime() < dateStart.getTime()) {
                callback(new Error(rule.message));
              }
            }

            callback();
          },
          object: this.value,
        },
        {
          type: 'date',
          message: this.$t('rules.firm_date_lower'),
          validator: Rules.dateLessThan,
          date: this.employeeEndDate,
        },
      ],
    };
  }

  getContractLabel(id: number | null) {
    const contract: Contract | null = id ? Contract.find(id) : null;
    return contract ? contract.name : this.$t('contract.new');
  }

  error(index: number): boolean {
    return !!this.errors.find(error => error.includes(`employeeHasContracts.${index}`));
  }

  add(): void {
    const employeeHasContract = new EmployeeHasContract();
    employeeHasContract.dateStart = this.employeeBeginDate || TODAY;
    employeeHasContract.employeeUuid = this.employeeUuid;

    this.value.push(employeeHasContract);
    this.$emit('add', employeeHasContract);
  }

  remove(index: number) {
    this.value.splice(index, 1);
    this.$emit('remove', index);
  }
}
