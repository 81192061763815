
















































import { Component, Prop } from 'vue-property-decorator';

import { FirmItemType } from '@/types/firm/firm';
import { FirmListAction, FirmListType } from '../../types/firm-list';

import FirmList from '../FirmList.vue';
import AbstractStep from './AbstractStep.vue';

const DeleteItemModal = () => import('../modal/DeleteItemModal.vue');
const TransferJobModal = () => import('../modal/TransferJobModal.vue');
const MoveJobModal = () => import('../modal/MoveJobModal.vue');
const AssignmentEmployeeModal = () => import('../modal/AssignmentEmployeeModal.vue');

@Component({
  components: {
    MoveJobModal,
    TransferJobModal,
    DeleteItemModal,
    AssignmentEmployeeModal,
    FirmList,
  },
})
export default class EmployeeStep extends AbstractStep {
  @Prop({ type: Boolean, default: true })
  readonly showEmployeeCheckbox!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideEmployee!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly editable!: boolean;

  showEmployee: number = 0;

  get firmListTypes(): Array<FirmListType> {
    const actions = [
      new FirmListType(FirmItemType.Unit, 'unit', false, (level, item) => item.type === 'unit' && level < 1),
      new FirmListType(FirmItemType.Job, 'job', !this.showEmployee, (level, item) => item.type === 'unit'),
    ];

    if (!this.hideEmployee) {
      actions.push(
        new FirmListType(FirmItemType.Employee, 'employee', !this.showEmployee, (level, item) => item.type === 'job'),
      );
    }

    return actions;
  }

  get actions(): Array<FirmListAction> {
    if (!this.editable) {
      return [];
    }

    return this.hideEmployee ? this.actionsWithoutEmployee : this.actionsAll;
  }

  get allowSelected(): Array<string> {
    return this.hideEmployee ? ['unit', 'job'] : ['unit', 'job', 'employee'];
  }

  get disableDraggable(): Array<string> {
    if (this.editable) {
      return [];
    }

    return ['unit', 'job', 'employee'];
  }

  get actionsAll(): Array<FirmListAction> {
    const actions = [];

    actions.push(new FirmListAction('edit', 'selectItem', (level, item) => item.type === 'unit' && level > 0));
    // actions.push(new FirmListAction('declaration.employee.affect', 'openModelAssignment', (level, item) => item.type === 'job'));
    actions.push(new FirmListAction('unit.add', 'addUnit', (level, item) => item.type === 'unit' && level < 1));
    // actions.push(new FirmListAction('job.add', 'addJob', (level, item) => item.type === 'unit'));
    actions.push(new FirmListAction('job.move', 'moveJob', (level, item) => item.type === 'job'));
    actions.push(new FirmListAction('delete', 'deleteItem', (level, item) => (item.type === 'unit' && level > 0) || (item.type === 'job' && item.isOldJob === true)));

    return actions;
  }

  get actionsWithoutEmployee(): Array<FirmListAction> {
    const actions = [];
    actions.push(new FirmListAction('declaration.employee.affect', 'openModelAssignment', (level, item) => item.type === 'job'));
    actions.push(new FirmListAction('edit', 'selectItem', (level, item) => item.type === 'employee'));
    actions.push(new FirmListAction('job.move', 'moveJob', (level, item) => item.type === 'job'));

    return actions;
  }
}
