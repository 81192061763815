




















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import _ from 'lodash';

import { FirmItemUpdateForm } from '@/types/firm/firm';
import { VForm } from '@/types/v-form';

import Unit from '@modules/declaration/entities/Unit';
import Timeout from '@/utils/Timeout';

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class UpdateUnit extends Vue implements FirmItemUpdateForm {
  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @Prop({ type: Object })
  value!: Unit;

  @Prop({ type: Boolean, default: false })
  isNew!: boolean;

  @firmUpdateModule.Action('addUnit')
  addUnit!: (unit: Unit) => Promise<Unit>;

  @firmUpdateModule.Action('updateUnit')
  updateUnit!: (unit: Unit) => Promise<Unit>;

  buffer: Unit = new Unit();
  disableSaveButton: boolean = false;

  $refs!: {
    form: VForm
  };

  get rules(): any {
    return {
      label: [
        { required: true, message: this.$t('form.required_field'), trigger: 'none' },
      ],
    };
  }

  get isModify() {
    this.sanitize();
    return !_.isEqual(this.value, this.buffer);
  }

  @Watch('value', { immediate: true })
  async onValueChange() {
    this.buffer = Object.assign(new Unit(), this.value);
  }

  sanitize() {
    this.buffer.label = this.buffer.label.trim().toUpperCase();
  }

  validate(e: Event): Promise<any> {
    e.preventDefault();

    this.disableSaveButton = true;
    this.sanitize();
    if (!this.isModify && !this.isNew) {
      this.$emit('valid');
      return Promise.resolve();
    }

    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      this.disableSaveButton = false;
      return Promise.reject(errorFields);
    }

    return this.save();
  }

  @Timeout()
  save() {
    const promise = this.buffer.new ? this.addUnit(this.buffer) : this.updateUnit(this.buffer);
    return promise.finally(() => {
      this.$emit('valid');
      this.disableSaveButton = false;
    });
  }
}
