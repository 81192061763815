import { FirmItemType, SelectableItem } from '@/types/firm/firm';

export class FirmListItem implements SelectableItem {
  type: FirmItemType = FirmItemType.Unit;
  uuid: string = '';
  label: string = '';
  labelFake?: string;
  ruuid?: string;
  children?: Array<FirmListItem> = [];
  remove?: boolean = false;
  isOldJob?: boolean = false;
}

export interface IListItemDrop {
  event: DragEvent;
  index: number;
  type: FirmItemType;
  parent: string;
  item: FirmListItem;
  list: Array<FirmListItem>;
  external: boolean;
}

export interface IListItemMoved {
  event: string;
  index: number;
  type: FirmItemType;
  parent: string;
  draggable: FirmListItem;
  list: Array<FirmListItem>;
}

export interface IListItem {
  item: FirmListItem;
  root: FirmListItem;
  index: number;
  list: Array<FirmListItem>;
}

export interface FirmListHandler {
  handler?: ((level: number, item: FirmListItem) => boolean) | boolean
}

export class FirmListAction implements FirmListHandler {
  label: string = '';
  action: string;
  handler?: ((level: number, item: FirmListItem) => boolean) | boolean;

  constructor(label: string, action: string, handler?: (level: number, item: FirmListItem) => boolean) {
    this.label = label;
    this.action = action;
    this.handler = handler || true;
  }
}

export class FirmListType implements FirmListHandler {
  type: FirmItemType;
  label: string = '';
  showOnSelect: boolean = true;
  handler?: ((level: number, item: FirmListItem) => boolean) | boolean;

  constructor(type: FirmItemType, label: string = '', showOnSelect: boolean = true, handler?: (level: number, item: FirmListItem) => boolean) {
    this.type = type;
    this.label = label;
    this.showOnSelect = showOnSelect;
    this.handler = handler || true;
  }
}
