<template>
  <div>
    {{ label }}
    <el-input v-if="!choices" v-model="filter" :placeholder="placeholder"></el-input>
    <el-select v-else v-model="filter">
      <el-option v-for="(label, value) in choices" :key="label" :value="value" :label="label">
        {{ label }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'TemplateFilter',
  props: {
    value: String,
    label: String,
    placeholder: {
      type: String,
      default: 'Rechercher',
    },
    choices: {
      type: Object,
    },
  },
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
