


















import {
  Component,
  Prop,
  PropSync,
  Vue,
} from 'vue-property-decorator';

@Component
export default class NotSavedModal extends Vue {
  @PropSync('show', { type: Boolean })
  readonly visible!: Boolean;

  @Prop()
  readonly options!: Record<any, any>;

  close() {
    this.$emit('cancel');
    this.$emit('update:show', false);
  }

  valid() {
    this.$emit('valid');
    this.$emit('update:show', false);
  }
}
